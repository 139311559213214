<template>
  <div>
    <v-simple-table
      v-if="!isMobile()"
      fixed-header
      height="calc(100vh - 370px)"
      class="table-padding-2"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader" class="text-center"></th>
            <th
              role="columnheader"
              class="text-center"
              style="min-width: 100px"
            >
              <SelectDeliveryCompany
                :label="$t('labels.delivery_company')"
                :placeholder="$t('labels.delivery_company')"
                name="id_delivery_company"
                sort-name="delivery_company_name"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th
              role="columnheader"
              class="text-center"
              style="min-width: 100px"
            >
              <DateRangeFilter
                :label="$t('labels.created_at')"
                :placeholder="$t('labels.created_at')"
                name="create_time"
                sort-name="create_time"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th
              role="columnheader"
              class="text-center"
              style="min-width: 100px"
            >
              <SelectWarehouse
                :label="$t('labels.warehouse')"
                :placeholder="$t('labels.warehouse')"
                name="id_warehouse"
                sort-name="warehouse_code"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th
              role="columnheader"
              class="text-center"
              style="min-width: 100px"
            >
              <SelectEmployee
                :label="$t('labels.employee')"
                :placeholder="$t('labels.employee')"
                name="id_employee"
                sort-name="employee_name"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th
              role="columnheader"
              class="text-center"
              style="min-width: 100px"
            >
              <SelectFilter
                :options="imageOptions"
                :label="$t('labels.report_image')"
                :placeholder="$t('labels.report_image')"
                name="has_image"
                sort-name="has_image"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th
              role="columnheader"
              class="text-center"
              style="min-width: 100px"
            >
              <InputFilterFromTo
                :label="$t('labels.order')"
                :placeholder="$t('labels.order')"
                name="count_order"
                sort-name="count_order"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th
              role="columnheader"
              class="text-center"
              style="min-width: 100px"
            >
              <InputFilter
                :label="$t('labels.tracking')"
                :placeholder="$t('labels.tracking')"
                name="order_tracking_id"
                @onFilter="onFilterChange"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.id" class="text-center">
            <td>
              <UploadHandoverFile
                v-if="checkRoleUpDownload(item)"
                :item="item"
                @refreshData="getList"
              />
            </td>
            <td>{{ item.delivery_company_name }}</td>
            <td>{{ formatDateTime(item.time) }}</td>
            <td>{{ item.warehouse_code }}</td>
            <td>{{ item.employee_name }}</td>
            <td>
              <HandoverImage :item="item" />
            </td>
            <td>{{ item.count_order }}</td>
            <td>
              <v-btn
                v-if="checkRoleUpDownload(item)"
                small
                color="success"
                outlined
                @click="downloadHandoverFile(item)"
              >
                <img
                  src="@/assets/common/download.png"
                  style="height: 24px"
                  alt=""
                />
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div v-else>
      <v-list
        dense
        style="height: calc(100vh - 270px)"
        class="overflow-x-hidden overflow-y-auto"
      >
        <div v-for="(item, index) in items" :key="item.id">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle class="font-weight-regular">
                <span class="font-weight-medium black--text">
                  {{ item.warehouse_code }}
                </span>
                -
                <span class="font-weight-medium warning--text">
                  {{ item.employee_name }}
                </span>
              </v-list-item-subtitle>
              <v-list-item-subtitle class="font-weight-regular">
                <span class="font-weight-medium black--text">
                  {{ item.delivery_company_name }}
                </span>
                -
                <span class="">
                  {{ formatDateTime(item.time) }}
                </span>
                -
                <span class="font-weight-medium black--text">
                  {{ item.count_order }} đơn
                </span>
              </v-list-item-subtitle>
              <v-list-item-subtitle class="font-weight-regular">
                <v-row no-gutters justify="center" align="center">
                  <v-col>
                    <UploadHandoverFile
                      v-if="checkRoleUpDownload(item)"
                      :item="item"
                      @refreshData="getList"
                    />
                  </v-col>
                  <v-col>
                    <v-btn
                      v-if="checkRoleUpDownload(item)"
                      small
                      color="success"
                      outlined
                      @click="downloadHandoverFile(item)"
                      class="mr-3"
                    >
                      <img
                        src="@/assets/common/download.png"
                        style="height: 24px"
                        alt=""
                      />
                    </v-btn>
                  </v-col>
                  <v-col>
                    <HandoverImage :item="item" />
                  </v-col>
                </v-row>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider v-if="index < items.length - 1"></v-divider>
        </div>
      </v-list>
    </div>
    <v-row class="pt-3">
      <v-col cols="12" class="text-right">
        <v-pagination
          v-model="page"
          :length="totalPage"
          :total-visible="6"
        ></v-pagination>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  debounce,
  formatDateTime,
  downloadPdfFile,
  downloadExcelFile,
  isMobile,
} from "@/libs/helpers";
import { httpClient } from "@/libs/http";
import { IMAGE_OPTIONS } from "@/libs/const";
import { generateRandomString } from "../../libs/helpers";
import moment from "moment";

export default {
  components: {
    SelectWarehouse: () => import("@/components/table/SelectWarehouse"),
    SelectDeliveryCompany: () =>
      import("@/components/table/SelectDeliveryCompany"),
    SelectEmployee: () => import("@/components/table/SelectEmployee"),
    DateRangeFilter: () => import("@/components/table/DateRangeFilter"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
    InputFilterFromTo: () => import("@/components/table/InputFilterFromTo"),
    InputFilter: () => import("@/components/table/InputFilter"),
    UploadHandoverFile: () =>
      import("@/components/goods_issue/UploadHandoverFile"),
    HandoverImage: () => import("@/components/goods_issue/HandoverImage"),
  },
  name: "HandoverHistory",
  data: () => ({
    page: 1,
    totalPage: 1,
    items: [],
    filters: {},
    sort: {},
    isLoading: false,
    imageOptions: [...IMAGE_OPTIONS],
  }),
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  methods: {
    formatDateTime,
    downloadPdfFile,
    downloadExcelFile,
    isMobile,
    checkRoleUpDownload(item) {
      if (!item.file && item.id_orders) {
        return false;
      }
      if (item.count_order < 1) {
        return false;
      }
      if (!window.me || !window.me.employee || !window.me.employee.id) {
        return false;
      }
      if (this.checkPermission(["download_handover_file"])) {
        return true;
      }
      const employeeId = window.me.employee.id;
      return employeeId === item.id_employee;
    },
    getList: debounce(function () {
      httpClient
        .post("/goods-issue-detail/v1/list-handover", {
          ...this.filters,
          page: this.page,
        })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.items = [...data.rows];
        });
    }, 500),
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    downloadHandoverFile(item) {
      this.downloadExcelFile(
        `${process.env.VUE_APP_API_V2_URL}/download/v1/handover-file`,
        {
          id: item.id,
        },
        `A4_${item.warehouse_code}_${item.delivery_company_name}_${moment(item.time).format('YYYYMMDD_HHmmss')}_${generateRandomString(6)}.xlsx`
      );
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style scoped></style>
